@value avatarSize: 140;
@value logoSize: 200;

.avatarAttach {
  margin-top: 10px;
  padding-bottom: 30px;
}

.placeholder {
  font-weight: bold;
  font-size: 36px;
  line-height: 49px;
  letter-spacing: 0.72px;
  opacity: 0.1;
}

.dropZone {
  padding: 0;
}

.avatarDropZone {
  composes: dropZone;
  width: calc(avatarSize * 1px);
  height: calc(avatarSize * 1px);
  margin: 0 auto;
  border-radius: 50%;
}

.avatarDropZoneOverlay {
  border-radius: 50%;
}

.logoDropZone {
  composes: dropZone;
  height: calc(logoSize * 1px);
}

.dragAndDropLabel {
  position: absolute;
  bottom: -30px;
  white-space: nowrap;
  text-align: center;
}

.withAvatarDropZone {
  border: none;
}

.avatarContainer {
  position: relative;
  border-radius: 50%;
}

.logoContainer {
  position: relative;
  border-radius: 10px;
}

.logoImg {
  max-width: 100%;
  max-height: calc(logoSize * 1px);
  object-fit: cover;
  border-radius: inherit;
}

.deleteIcon {
  opacity: 0.3;
}

.deleteButton {
  pointer-events: initial;
}

.deleteButton:hover > .deleteIcon {
  opacity: 1;
}

.avatarDeleteButton {
  composes: deleteButton;
  position: absolute;
  bottom: 0;
  left: calc(50% + avatarSize / 2 * 1px - 5px);
}

.logoDeleteButton {
  composes: deleteButton;
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 10px;
  background: #fff;
  border-radius: 10px 0 0 0;
}

.logoDeleteButton:hover {
  background: #fff;
}
