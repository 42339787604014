@import 'variables';

@function get-dimension($element, $dimension) {
  @return map-get(map-get($elementDimensions, $element), $dimension);
}

@function get-point($type, $axis) {
  @return map-get(map-get($basePoints, $type), $axis);
}

@function number-to-px($number) {
  @return $number * 1px;
}
