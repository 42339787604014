@import '~@appclose/ui/src/scss/functions';
@import '~@appclose/ui/src/components/Typography/scss/mixins';

.list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, min-content));
  gap: 4px;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    white-space: nowrap;

    &:not(:last-of-type) {
      @include text(4);

      cursor: pointer;
      transition: color 0.3s ease;

      &:not(:hover) {
        color: get-color('text', 'secondary');

        svg {
          fill: get-color('text', 'secondary');
        }
      }
    }

    &:last-of-type {
      @include caption(2, 'bold');
    }
  }
}

.chevron {
  vertical-align: sub;
}
