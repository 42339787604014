@import '~@appclose/ui/src/scss/mixins';

@include desktop {
  .billableToggleWrapper {
    grid-column: 1 / 3;
  }

  .totalFieldWrapper {
    grid-column: 2 / 3;
  }
}

