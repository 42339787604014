.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;
}

.text {
  margin-bottom: 54px;
  color: var(--color-info);
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
}

.button {
  margin-bottom: 13px;
}
