.note {
  margin: 30px 0 10px;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.3px;
  opacity: 0.5;
}

.icon {
  flex-shrink: 0;
  margin-right: 20px;
}

.balanceDue {
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
}

.payment > span {
  font-size: 13px;
}

.table {
  table-layout: fixed;
}

.table tr:last-child {
  border-bottom: 1px solid #ebeeee;
}
