.roundButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 50%;
  transition: background-color ease 0.5s;
}

.roundButton,
.roundButton:active,
.roundButton:focus {
  background-color: #f8f9fa;
}

.roundButton:hover {
  background-color: var(--color-primary);
  opacity: 1 !important;
}

.void > *,
.void:hover > * {
  filter: none;
  transition: none;
}

.roundButton:hover > * {
  filter: invert(100%) brightness(150%) contrast(100%);
  transition: filter ease 0.6s;
}

.success > img,
.primary > img,
.info > img {
  filter: invert(100%) brightness(150%) contrast(100%);
}

.success {
  background-color: var(--color-success);

  &.loading,
  &:active,
  &:hover,
  &:focus {
    background-color: var(--color-success);
  }
}

.primary {
  background-color: var(--color-primary);

  &.loading,
  &:active,
  &:hover,
  &:focus {
    background-color: var(--color-primary);
  }
}

.secondary {
  background-color: var(--color-info);

  &.loading,
  &:active,
  &:hover,
  &:focus {
    background-color: var(--color-info);
  }
}

.warning {
  background-color: var(--color-warning);

  &.loading,
  &:active,
  &:hover,
  &:focus {
    background-color: var(--color-warning);
  }
}

.yellow {
  background-color: var(--color-brand);

  &.loading,
  &:active,
  &:hover,
  &:focus {
    background-color: var(--color-brand);
  }
}

.void {
  background-color: var(--color-default);

  &.loading,
  &:active,
  &:hover,
  &:focus {
    background-color: var(--color-default);
  }
}
