@value highlightColor: #f8f9fa;

.expanded {
  background-color: highlightColor;
  border-radius: 8px;
  cursor: pointer;
}

.additionalRow > td {
  height: auto;
  padding-top: 5px;
  padding-bottom: 5px;
}

.additionalRow:nth-child(2) > td {
  padding-top: 18px;
}

.additionalRow:last-child td {
  padding-bottom: 18px;
}

.mainRow:first-child > td:first-child {
  border-radius: 8px 0 0 0;
}

.mainRow:first-child > td:last-child {
  border-radius: 0 8px 0 0;
}

.lastRow:last-child > td:first-child {
  border-radius: 0 0 0 8px;
}

.lastRow:last-child > td:last-child {
  border-radius: 0 0 8px 0;
}
.expanded tr:first-child td:nth-child(n + 4) {
  border-bottom: 1px solid #ebeeee;
}

.expanded > tr {
  border-top: none !important;
}

.expanded > tr:not(:first-child):not(:last-child) > td {
  border-radius: 0 !important;
}

.expandCell:hover > svg {
  fill: var(--color-primary);
}

.notExpandedIcon {
  transform: rotate(90deg);
}
