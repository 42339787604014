@import '~@appclose/ui/src/scss/mixins';

.fee {
  padding: 10px 15px;
  background-color: var(--color-utility);
  border-radius: 12px;
}

.fee *:not(:last-child) {
  margin-right: 7px;
}

.fixed {
  white-space: nowrap;
}

@include mobile {
  .fee {
    justify-content: center;
  }
}
