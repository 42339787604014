@value default: #ebeeee;
@value transitionDuration: 0.3s;

.wizardProgress {
  margin-bottom: 40px;
}

.stepNumber {
  color: var(--color-info);
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.3px;
}

.stepTitle {
  margin: 0;
  color: var(--color-info);
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.3px;
  transition: color transitionDuration ease;
}

.stepTitleActive {
  color: var(--color-default);
}

.progressContainer {
  position: relative;
  margin-bottom: 25px;
}

.firstStep {
  margin-left: 5px;
}

.lastStep {
  margin-right: 5px;
}

.progressBar {
  position: absolute;
  right: 0;
  left: 0;
  height: 2px;
  background: default;
}

.progressBarActive {
  composes: progressBar;
  right: 100%;
  background: var(--color-success);
  transition: right transitionDuration ease;
}

.progressBarActiveComplete {
  right: 0;
}

.stepPoint {
  position: absolute;
  top: -5px;
  left: 0;
  z-index: 1;
  width: 12px;
  height: 12px;
  background: default;
  border-radius: 50%;
  transition: border 0s ease transitionDuration;
}

.stepPointCurrent {
  box-sizing: border-box;
  background: #fff;
  border: 2px solid var(--color-success);
  border-radius: 50%;
  transition: border 0s ease transitionDuration,
    background 0.1s ease transitionDuration;
}

.stepPointCompleted {
  top: -10px;
  left: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  background: var(--color-success);
  box-shadow: 0 5px 10px rgba(164, 230, 80, 0.3);
  transition: background 0s ease 0s;
}

.stepPointLast {
  composes: stepPoint;
  right: 0;
  left: unset;
}
