.table {
  box-sizing: border-box;
  margin-bottom: 60px;
  border: 1px solid #ebeeee;
  border-radius: 10px;
}

.tableSection {
  display: grid;
  grid-gap: 15px 20px;
  grid-template-columns: 1fr 20%;
  align-items: baseline;
  padding: 40px;
  font-weight: bold;
}

.tableSection:not(:last-of-type) {
  border-bottom: 1px solid #ebeeee;
}

.ledgerTitle {
  margin: 0;
  font-size: 13px;
  line-height: 15px;
}

.ledgerBalance,
.unclearedBalance {
  font-size: 13px;
  line-height: 15px;
}

.totalBalance {
  font-size: 18px;
  line-height: 21px;
}
