.field {
  font-size: 18px;
}

.field input {
  font-size: 18px;
}

.email {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  opacity: 0.5;
}

.accountStatementLinkToggle {
  margin-bottom: 40px;
}
