@import '~@appclose/ui/src/scss/functions';
@import '~@appclose/ui/src/components/Typography/scss/mixins';

.label {
  @include caption(2, 'bold');

  padding: 2px 12px;
  color: #fff;
  white-space: nowrap;
  border-radius: 12px;
}

.primary {
  background-color: var(--color-primary);
}

.info {
  background-color: var(--color-info);
}

.success {
  background-color: var(--color-success);
}

.warning {
  background-color: var(--color-warning);
}

.default {
  background-color: var(--color-default);
}

.brand {
  color: var(--color-default);
  background-color: var(--color-brand);
}

.tertiary {
  color: get-color('text', 'primary');
  background-color: get-color('background', 'tertiary');
}
