.action {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 100%;
  box-shadow: none;
  cursor: pointer;

  &.loading {
    background-size: 100%;
  }
}
