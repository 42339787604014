@import '~@appclose/ui/src/scss/mixins';

.timer {
  display: flex;
  align-items: center;
}

.action {
  margin-right: 20px;
}

.time {
  margin: 0;
  font-weight: bold;
  font-size: 26px;
  line-height: 36px;
  letter-spacing: 1.86px;
}

.paused {
  opacity: 0.3;
}

.pointer {
  cursor: pointer;
}

.count {
  opacity: 0.3;
}

.controls {
  display: flex;
  align-items: center;
  margin-left: 40px;
}

.control {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  min-height: auto;
  padding: 0;
  line-height: 35px;
  text-align: center;
  border-radius: 50%;
}

.control:not(:last-child) {
  margin-right: 5px;
}

.loading {
  background-size: 20px;
}

.compact .action {
  margin-right: 15px;
}

.compact .time {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 1.07px;
}

.compact .controls {
  margin-left: 20px;
}

@include mobile {
  .compact .control {
    width: 30px;
    height: 30px;
  }

  .compact .controls {
    margin-left: 10px;
  }
}
