.text {
  margin: 0 0 30px;
  font-size: 15px;
  line-height: 22px;
}

.card {
  margin: 57px 0 69px;
}

.list {
  margin-right: 80px;
}

.item {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}

.item b {
  padding-left: 10px;
  color: var(--color-info);
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.3px;
}

.value {
  margin: 0;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
}

.value span {
  color: var(--color-info);
}
