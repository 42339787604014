@import '~@appclose/ui/src/scss/mixins';
@value footer from './components/Footer/Footer.module.scss';

.cardHead {
  height: 24px;
  background-color: var(--color-primary);
  border-radius: 20px 20px 0 0;
}

.cardHeadSuccess {
  background-color: var(--color-success);
}

.cardHeadCreated {
  background-color: var(--color-info);
}

.cardHeadVoided {
  background-color: var(--color-default);
}

.cardHeadOverdue {
  background-color: var(--color-warning);
}

.cardBody {
  padding: 50px 60px 60px;
  background-color: white;
  border-radius: 0 0 20px 20px;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.05);
}

.cardBodyEmail {
  padding: 36px 50px 50px;
}

.cardBody p {
  margin: 0;
}

.cardBodyVoided > div:not(.footer) span,
.cardBodyVoided > div:not(.footer) p,
.cardBodyVoided > div:not(.footer) time {
  opacity: 0.5;
}

.cardDates {
  margin-bottom: 10px;
}

.cardBody .cardLabel {
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.3px;
  opacity: 0.5;
}

.dueDateLabel {
  flex-shrink: 0;
  padding: 0 10px;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.3px;
}

@include mobile {
  .cardBody {
    padding: 36px 30px;
  }
}
