.modal {
  max-width: 440px;
}

.modal p {
  margin: 0 0 30px;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
}

.check {
  text-align: center;
}

.check > label {
  color: var(--color-info);
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.3px;
}

.buttons {
  flex-direction: column;
  align-items: center;
  margin-top: 0;
}

.buttons > button:first-child {
  margin-right: 0;
}
