.fieldset {
  width: 100%;
  padding-top: 15px;

  h4 {
    width: 100%;
  }

  h4 > div {
    width: 100%;
  }
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.list li:not(:last-child) {
  margin-bottom: 15px;
}

.icon {
  text-align: center;
}

.icon svg path {
  fill: var(--color-primary);
}
