@import '~@appclose/ui/src/scss/functions';

.detailsRow {
  height: 60px;
}

.detailsRow > div:nth-child(2) {
  padding-right: 16px;
}

.detailsRow > *:not(:first-child) {
  border-bottom: 1px solid get-color('background', 'tertiary');
}

.icon {
  align-self: center;
}

.invoice {
  margin-top: 60px;
}

.invoice > p {
  color: var(--color-info);
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.3px;
}
