@import '~@appclose/ui/src/scss/mixins';

.fees {
  padding: 30px 35px 10px;
  border-radius: 32px;
}

.solutions {
  margin-top: 30px;
}

.minifySolutions {
  position: absolute;
  width: 100%;
  padding: 30px 0 20px;
  background-color: #fff;
}

.features {
  margin-top: 40px;
  padding: 0 20px;
}

.fadeInUp :global {
  animation: fadeInUp 0.6s ease-out forwards;
}

.fadeOutUp :global {
  animation: fadeOutUp 0.6s ease-in forwards;
}

@include mobile {
  .fees {
    padding-right: 0;
    padding-left: 0;
  }

  .features {
    margin-top: 60px;
    padding: 0;
  }
}
