@import '~@appclose/ui/src/scss/functions';

.container {
  width: fit-content;
  padding: 2px 12px;
  color: get-color('text', 'contrast');
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  border-radius: 12px;

  &.failed {
    background: get-color('system', 'warning');
  }
  &.success {
    background: get-color('system', 'success');
  }
  &.pending {
    background: get-color('system', 'gray');;
  }
}
.resyncIcon:hover {
  cursor: pointer;
}
