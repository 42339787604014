.header {
  margin-bottom: 20px;
}

.associatedInvoices {
  margin-bottom: 60px;
}

.associatedInvoice {
  margin: 0 0 15px;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.3px;
  opacity: 0.5;
}

.invoiceInfo {
  margin-bottom: 40px;
}
