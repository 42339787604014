.icon {
  height: 0;
}

.icon > svg {
  transform: translateY(-50%);
}

.error {
  margin: 0;
  color: #fff;
}
