@import '~@appclose/ui/src/scss/mixins';

.modal {
  max-width: 1290px !important;
}

.date {
  margin: 10px 0 0 20px;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.32px;
}

@include mobile {
  .date {
    margin: 6px 0 0 15px;
  }
}
