.label {
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: auto 1fr;
  align-items: center;
  color: var(--color-info);
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.3px;
}

.textContainer {
  display: flex;
  justify-items: center;
}

.textContainer span {
  margin-right: 5px;
}

.tooltip {
  max-width: 300px;
}
