@import '~@appclose/ui/src/scss/mixins';

.mode {
  padding: 60px;
  background-color: #fff;
  border-radius: 32px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);
}

.icon {
  margin-bottom: 35px;
}

.price {
  font-weight: 900;
  font-size: 40px;
  line-height: 55px;
  letter-spacing: 2px;
}

.users {
  margin: 8px 0 30px;
  padding: 3px;
  background-color: rgba(32, 194, 210, 0.1);
  border-radius: 8px;
}

.users p {
  font-weight: bold;
  line-height: 20px;
}

.anchor {
  margin-left: 6px;
}

.minify {
  padding: 25px 40px;
  transition: opacity;
}

.minify .price {
  font-weight: 900;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 1.2px;
}

@include desktop {
  .description {
    height: 42.824295010845987%;
  }
}

@include mobile {
  .mode {
    padding: 30px;
  }

  .title {
    font-size: 30px;
    line-height: 41px;
    letter-spacing: 0.8px;
  }

  .priceWrapper {
    align-items: center;
  }

  .price {
    font-size: 34px;
    line-height: 46px;
  }
}
