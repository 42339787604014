.sidebar {
  width: 440px;
  margin-left: 60px;
}

.buttons {
  margin-top: 100px;
}

.buttons > button:not(:first-child) {
  margin-left: 20px;
}
