.fieldset > div {
  padding-right: 0;
  padding-left: 0;
}

.summary {
  box-sizing: border-box;
  min-height: 700px;
  border: 1px solid #ebeeee;
  border-radius: 10px;
}

.summary > div {
  padding: 40px;
}

.summary > div:not(:last-child) {
  border-bottom: 1px solid #ebeeee;
}

.smallText {
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
}

.difference {
  composes: smallText;
  opacity: 0.5;
}

.mediumText {
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
}

.bigText {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
}
