.checkOption {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color-info);
  cursor: pointer;
}

.check {
  display: grid;
  grid-row-gap: 8px;
  width: 140px;
  height: 180px;
  margin-bottom: 20px;
  padding: 15px 14px;
  border: 2px solid transparent;
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.05);
}

.checkOption.selected {
  color: var(--color-default);
}

.checkOption.selected .check {
  border: 2px solid #a4e650;
}
