.timerForm {
  margin-top: 30px;
}

.field {
  overflow: hidden;
}

.field label {
  padding-top: 0;
}

.select > div,
.field input,
.field textarea {
  font-size: 14px;
  line-height: 19px;
}
