.buttonLabel {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  .caption {
    max-width: max-content;
    word-break: normal;
  }
}

.buttonLabel svg {
  fill: #fff;
}

.loading {
  pointer-events: none;
}

.actionLabel {
  text-align: center;
}

.disabled {
  cursor: not-allowed;
}

.disabled > strong {
  opacity: 0.4;
}
