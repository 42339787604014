.businessOwners > div > fieldset + button {
  top: 6px;
}

.hint {
  margin-left: 5px;
}

.hintContent {
  max-width: 400px;
  margin: 0;
  text-align: justify;
}

.text {
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.3px;
}

.note {
  composes: text;
  margin: 40px 0 20px;
  opacity: 0.5;
}
