.privacy {
  display: inline-block;
  max-width: 100%;
  color: transparent;
  background: #f8f9fa;
  border-radius: 4px;
  user-select: none;
}

.privacy > * {
  visibility: hidden;
}

tr:hover .privacy {
  background: #fff;
}
