.integrationIcon {
  width: 100%;
  max-width: 531px;
  height: 100%;
  max-height: 191px;
}

.statusIcon {
  margin-top: 20px;
}

.text {
  margin-bottom: 70px;
  font-size: 15px;
  line-height: 22px;
}

.integrationResult {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}

.integrationResultSync {
  composes: integrationResult;
  align-self: start;
}

.integrationResult span {
  margin-left: 10px;
  color: var(--color-info);
  font-weight: normal;
}

.integrationResultError {
  composes: integrationResultSync;
  color: var(--color-info);
}

.integrationResultInfo {
  margin: 20px 0 0;
  font-size: 15px;
  line-height: 22px;
}
