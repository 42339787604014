.icon {
  margin-top: 25px;
}

.text {
  margin-top: 10px;
  margin-bottom: 55px;
  font-size: 15px;
  line-height: 22px;
}
