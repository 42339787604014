.context {
  padding: 24px 24px 24px 16px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 10px 35px rgba(0, 0, 0, 0.08);
}

.button {
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 7px 11px;
}
