@import '~@appclose/ui/src/scss/mixins';

.block {
  padding: 60px;
}

.input {
  width: 100%;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
}

@include mobile {
  .block {
    padding: 0;
    box-shadow: none;
  }
}
