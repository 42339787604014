@import 'scss/functions';

.preview {
  width: number-to-px(get-dimension('preview', 'width'));
  height: number-to-px(get-dimension('preview', 'height'));
  background-size: contain;
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.05);
}

.value {
  width: number-to-px(get-dimension('value', 'width'));
  height: number-to-px(get-dimension('value', 'height'));
}

.check {
  padding: number-to-px(get-point('check', 'y')) 0 0
    number-to-px(get-point('check', 'x'));
  background: transparent url('assets/check-background.svg') no-repeat top /
    contain;
}

.check .value {
  background: transparent url('assets/check-grid-value.svg') no-repeat top /
    contain;
}

.depositSlip {
  padding: number-to-px(get-point('deposit-slip', 'y')) 0 0
    number-to-px(get-point('deposit-slip', 'x'));
  background: transparent url('assets/deposit-slip-background.svg') no-repeat
    top / contain;
}

.depositSlip .value {
  background: transparent url('assets/deposit-slip-grid-value.svg') no-repeat
    top / contain;
}
