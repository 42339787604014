@import '~@appclose/ui/src/scss/mixins';

.container {
  padding: 0 60px 40px;
}

.note {
  margin: 24px 0 20px;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.3px;
  text-align: center;
  opacity: 0.5;
}

.submit {
  margin-top: 40px;
}

@include mobile {
  .container {
    padding: 0 20px 20px;
  }

  .submit {
    margin-top: 20px;
  }
}
