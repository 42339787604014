.footer {
  margin-top: 40px;
}

.noteText {
  color: var(--color-info);
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.3px;
}

.invoiceFooter {
  margin-top: 20px;
}

.invoiceFooter button:not(.invoiceDetailsButton) {
  min-height: 48px;
}

.notClickable {
  pointer-events: none;
}

.voidedMessage {
  align-self: flex-end;
  margin-bottom: 0;
  color: var(--color-warning);
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.3px;
}

.voidedMessageEmail {
  position: relative;
  top: -10px;
}

.invoiceDetailsButton {
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.3px;
}

.invoiceDetailsButton svg {
  margin-right: 10px;
}

.voidedMessage + .invoiceDetailsButton,
.noteText + .invoiceDetailsButton {
  margin-top: 10px;
}
