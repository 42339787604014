.toggle {
  margin-top: 40px;
}

.attorney {
  display: flex;
  align-items: center;
  margin: 0;
}

.attorneyAvatar {
  margin: 0 10px 0 0;
}
