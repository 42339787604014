@import '~@appclose/ui/src/scss/mixins';

.note {
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.3px;
}

.invoicesNote {
  composes: note;
  margin: 0 0 10px;
  opacity: 0.5;
}

.additionalInfo {
  margin-bottom: 40px;
}

.termsAndConditionals {
  margin-bottom: 60px;
}

@include mobile {
  .invoicesNote {
    margin-top: 0;
  }

  .terms {
    font-size: 15px;
  }
}
