@import '~@appclose/ui/src/scss/mixins';

$padding: 15px;
$lineWidth: 2px;
$iconWidth: 30px;
$color: #33414780;

.invoiceHistoryItem {
  position: relative;
  display: grid;
  grid-column-gap: 30px;
  grid-template-columns: auto auto 1fr;
  padding: $padding 0;
  list-style: none;
}

@include mobile {
  .invoiceHistoryItem {
    grid-template-columns: auto 1fr;
  }
}

.invoiceHistoryItem:not(:last-child)::after {
  position: absolute;
  top: calc(#{$padding} + 25px);
  right: calc(100% - #{$iconWidth} / 2 - #{$lineWidth} / 2);
  bottom: calc(5px - #{$padding});
  left: calc(#{$iconWidth} / 2 - #{$lineWidth} / 2);
  background: $color;
  opacity: 0.5;
  content: '';
}

.statusIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 22px;
}

.date {
  flex-shrink: 0;
  width: 150px;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.3px;
  opacity: 0.5;
}

.description {
  align-self: flex-start;
  font-size: 16px;
  line-height: 22px;
}

.description b {
  font-weight: 600;
}
