.info {
  margin: 10px 0 0;
}

.info svg {
  margin-right: 10px;
}

.note {
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.3px;
}
