.title {
  margin: 0 0 8px;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.3px;
  opacity: 0.5;
}

.file {
  margin: 8px 20px 0 0;
}

.unselectedFile > span,
.unselectedFile > svg {
  color: var(--color-default);
  opacity: 0.3;
}

.checkBox {
  margin-left: 8px;
  line-height: 0;
  pointer-events: none;
}
