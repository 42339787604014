@import '~@appclose/ui/src/components/Typography/scss/mixins';
@import '~@appclose/ui/src/scss/functions';

.form {
  input[type='radio'] + label {
    span {
      margin-right: 12px;
    }

    div {
      @include text(4, 'semiBold');
    }
  }
}

.note {
  @include text(4);
}

.grid {
  margin-top: 20px;
  padding: 20px;
  border-radius: 12px;
}

.dark {
  background-color: get-color('background', 'black');
}
