.scheduleLabel {
  margin: 10px 0 15px 0;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.3px;
  opacity: 0.5;
}

.editButton {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.5px;
}

.editButton svg {
  margin-right: 10px;
}

.editButton span {
  margin-top: 5px;
}
