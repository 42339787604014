@import '~@appclose/ui/src/scss/mixins';

.content {
  max-height: calc(5 * 60px);
  overflow-y: auto;
}

.activity {
  margin: 0 0 5px;
  color: var(--color-default);
  font-weight: 500;
  font-size: 15px;
}

.emptyResult {
  margin: 0;
  font-size: 13px;
}

.total {
  min-height: 60px;
}

.totalTitle {
  margin: 0;
  padding-left: 0;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.3px;
}

.totalAmount {
  margin-right: 20px;
  font-weight: bold;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.error {
  text-align: unset;
}

@include mobile {
  .activity {
    margin: 0 !important;
  }
}
