@import '~@appclose/ui/src/scss/mixins';

:global(.shepherd-header) {
  flex-direction: row-reverse;
  padding: 0 !important;
  background-color: transparent !important;
  border-radius: 20px;
}

:global(.shepherd-element) {
  padding: 40px 40px 24px;
  border-radius: 20px;
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.05);
}

:global(.shepherd-cancel-icon) {
  position: absolute;
  top: 17px;
  left: 22px;
  color: var(--color-default) !important;
  font-size: 1.5em;
  line-height: 1;
}

:global(.shepherd-progress) {
  margin: -22px 0 8px;
  color: var(--color-info);
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.3px;
  text-align: center;
}

:global(.shepherd-title) {
  display: block;
  margin-bottom: 8px;
  color: var(--color-default);
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  text-align: center;
}

:global(.shepherd-text) {
  margin-bottom: 24px;
  padding: 0;
  color: var(--color-default);
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  text-align: center;
}

:global(.shepherd-footer) {
  justify-content: center;
  padding: 0;
}

:global(.shepherd-button) {
  margin-right: 20px;
  padding: 9px 24px;
  color: var(--color-default) !important;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.2px;
  background-color: #fff;
  border-radius: 100px;
  outline: none;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

:global(.shepherd-arrow::before) {
  background-color: #fff !important;
}

:global(.shepherd-button:hover) {
  background-color: #fff !important;
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.05);
}

:global(.shepherd-button:active) {
  background-color: var(--color-utility) !important;
  box-shadow: none;
}

:global(.shepherd-cancel-icon:hover) {
  opacity: 0.75;
}

:global(.shepherd-modal-overlay-container.shepherd-modal-is-visible) {
  opacity: 0.2;
  transition: background-color 0ms;
}

:global(.shepherd-button.shepherd-button-secondary) {
  color: #fff !important;
  background-color: var(--color-primary);
  box-shadow: 0 5px 12px rgba(32, 194, 210, 0.3);
}

:global(.shepherd-button.shepherd-button-secondary:hover) {
  background-color: #25ccdd !important;
}

:global(.shepherd-button.shepherd-button-secondary:active) {
  background-color: #02bed0 !important;
  box-shadow: none;
}

@include mobile {
  :global(.shepherd-element) {
    width: calc(100% - 20px);
  }
}