.modal {
  min-width: 1000px;
}

.noteText {
  margin-bottom: 32px;
  color: var(--color-info);
  font-size: 16px;
  line-height: 15px;
  letter-spacing: 0.3px;
}

#payment-form {
  min-height: 400px;
  margin-bottom: 48px;
}
