@import '~@appclose/ui/src/scss/mixins';

.disclaimer {
  padding: 20px 0;
}

.icon {
  margin-right: 13px;
}

.disclaimer ol,
.disclaimer ul {
  margin: 15px 0;
  padding: 0;
  list-style: none;
}

.disclaimer ol {
  margin-top: 40px;
  counter-reset: number;
}

.disclaimer ol > li::before {
  font-weight: bold;
  content: counter(number) '. ';
  counter-increment: number;
}

.disclaimer ul {
  padding-left: 20px;
  counter-reset: letter;
}

.disclaimer ul > li::before {
  font-weight: bold;
  content: counter(letter, lower-alpha) '. ';
  counter-increment: letter;
}

.disclaimer li {
  margin-bottom: 15px;
}

@include mobile {
  .disclaimer ol,
  .disclaimer ul {
    font-size: 13px;
    line-height: 18px;
  }

  .disclaimer ol > li::before,
  .disclaimer ul > li::before,
  .disclaimer ol b,
  .disclaimer ul b {
    font-weight: 800;
  }

  .link {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.3px;
  }
}
