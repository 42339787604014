@import '~@appclose/ui/src/scss/mixins';

.buttons {
  margin-top: 60px;
}

@include mobile {
  .tabList {
    padding: 20px 0;
    overflow-x: auto;
  }

  .tabList > li:not(:first-child) {
    margin-left: 24px;
  }
}
