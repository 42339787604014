@import '~@appclose/ui/src/scss/mixins';

.content {
  max-height: calc(4 * 64px + 30px);
  overflow-y: auto;
}

.contentQbo {
  max-height: calc(5 * 64px + 30px);
}

.table tbody tr:last-child {
  border-bottom: 1px solid #ebeeee;
}

.emptyResult {
  margin: 0;
  font-size: 13px;
}

.total {
  min-height: 60px;
}

.totalTitle {
  margin: 0;
  padding-left: 0;
  font-weight: 600;
  font-size: 13px;
}

.totalAmount {
  margin-right: 20px;
  font-weight: bold;
}

@include mobile {
  .totalAmount {
    margin-right: 0;
  }
}
