@import '~@appclose/ui/src/scss/mixins';

.invoiceWrapper {
  position: relative;
  cursor: pointer;
}

.corner {
  position: absolute;
  top: 28px;
  left: 24px;
  transform: rotate(-90deg);
}

.expandedCorner {
  top: 50px;
  transform: rotate(-180deg);
}

.block {
  margin-bottom: 15px;
  padding: 20px 30px 15px 70px;
}

.text {
  font-size: 15px;
  line-height: 18px;
}

.smallText {
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.3px;
}

.invoiceNumber {
  composes: text;
  margin: 0;
  font-weight: 500;
}

.createdAt {
  composes: smallText;
  margin-top: 5px;
  opacity: 0.5;
}

.account {
  composes: text;
  font-weight: 500;
}

@include mobile {
  .block {
    padding: 20px 30px 15px 50px;
  }

  .block svg {
    margin-right: 20px;
  }

  .block p {
    margin: 0 0 10px;
  }

  .corner {
    top: 32px;
    left: 20px;
  }

  .expandedCorner {
    top: 40px;
    left: 15px;
  }
}
