.contactMatterFieldset {
  margin-bottom: 0;
}

.unclearedTransactionsTitle {
  margin-top: 30px;
  color: var(--color-info);
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.3px;
}

.unclearedTransactions {
  margin-bottom: 20px;
}
