@import '~@appclose/ui/src/scss/mixins';

.block {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-height: 60px;
  padding: 0;
  box-shadow: none;
}

.block span {
  font-size: 26px;
  line-height: 30px;
  letter-spacing: 1.25px;
}

@include mobile {
  .block {
    justify-content: flex-start;
  }
}
