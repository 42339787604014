.overlay {
  width: 790px;
}

.table {
  width: 100%;
  max-height: 1000px;
  overflow-y: auto;
}

.table > tbody:not(:first-of-type) {
  border-top: 1px solid #ebeeee;
}

.checkbox {
  line-height: 0;
}
