.fieldset {
  h4 {
    width: 100%;
  }

  h4 > div {
    width: 100%;
  }
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.list li:not(:last-child) {
  margin-bottom: 20px;
}

.tick path {
  fill: var(--color-primary);
}
