.table tbody tr:last-child {
  border-bottom: 1px solid #ebeeee;
}

.activityCell {
  max-width: 0;
}

.activity {
  margin: 0 0 5px;
  color: var(--color-default);
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
}
