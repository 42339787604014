.title {
  margin: 40px 0 10px;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.3px;
  opacity: 0.5;
}

.row {
  min-height: 47px;
  border-bottom: 1px solid #ebeeee;
}

.cell {
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.paymentDate {
  text-align: left;
}

.amount {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
}

.status {
  text-align: right;
}

.expand {
  margin: 12px 0;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.3px;
  cursor: pointer;
}

.expandIcon {
  display: inline-block;
  width: 16px;
  height: 10px;
  margin-right: 10px;
  background: transparent url('~@appclose/icons/src/assets/arrow-down.svg')
    center no-repeat;
  opacity: 0.5;
  transition: transform ease 0.5s;
}

.expanded .expandIcon {
  transform: scaleY(-1);
}
