.contactMatter {
  margin: 0;
}

.contact {
  color: var(--color-default);
}

.matter {
  color: var(--color-info);
}

.trustBalance {
  margin-left: 15px;
  color: var(--color-default);
}
