@keyframes :global(fadeOutUp) {
  from {
    opacity: 1;
  }

  to {
    transform: translate3d(0, -100%, 0);
    opacity: 0;
  }
}
