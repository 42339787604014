.separator {
  font-weight: 500;
  font-size: 20px;
}

.field {
  text-align: center;
}

.durationField {
  width: fit-content;
}
