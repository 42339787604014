@import '~@appclose/ui/src/scss/mixins';
@value padding: 40px;

.timers {
  z-index: 1;
  width: 360px;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.03);
}

.header {
  display: flex;
  align-items: center;
  height: 90px;
  padding: 0 padding;
  border-bottom: 1px solid #ebeeee;
}

.ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.li {
  padding: 35px padding padding;
  border-bottom: 1px solid #ebeeee;
}

@include mobile {
  .timers {
    width: 340px;
  }
}
