@import '~@appclose/ui/src/scss/mixins';

.modal {
  max-width: 1120px !important;
}

@media (max-width: 1160px) {
  .modal {
    max-width: 1020px !important;
  }
}

@include mobile {
  .modal {
    max-width: 100% !important;
  }
}
