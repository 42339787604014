.field {
  margin-top: 40px;
}

.fieldShortened {
  margin-top: 4px;
}

.amountInfo {
  margin-right: 40px;
}

.amountLabel {
  font-weight: bold;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 1.8px;
  text-transform: uppercase;
}

.amount {
  font-weight: bold;
  font-size: 36px;
  line-height: 49px;
  letter-spacing: 0.72px;
  text-align: center;
}

.additionalAmountInfo {
  margin-top: 10px;
  margin-bottom: 5px;
}
.additionalAmountInfo .amountField {
  padding-top: 0;
}

.additionalAmountInfo .amountField:first-of-type {
  margin-right: 40px;
}

.additionalAmountInfo span {
  position: initial;
  display: block;
  color: var(--color-default);
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.3px;
  white-space: nowrap;
}

.creditMemo {
  padding-top: 10px;
}

.additionalAmountInfo .additionalAmount {
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0.44px;
}

.unpaidAmount {
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0.44px;
}
