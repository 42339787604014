@import '~@appclose/ui/src/scss/mixins';

.actions {
  margin-top: 30px;
}

.action {
  display: flex;
  align-items: center;
  margin-left: 40px;
  font-weight: 600;
  font-size: 15px;
  line-height: normal;
  letter-spacing: 0.5px;
  word-break: keep-all;
}

.icon {
  flex-shrink: 0;
  margin-right: 8px;
}

.deleteIcon {
  composes: icon;
}

.deleteIcon > * {
  stroke: var(--color-default);
}

.noteText {
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.3px;
  opacity: 0.5;
}

@include mobile {
  .nonPaymentActions:not(:empty) {
    margin-top: 40px;
  }

  .action:first-child {
    margin-left: 0;
  }
}
