@import '~@appclose/ui/src/themes/base.module.scss';
@import '~@appclose/ui/src/animations/fadeInUp.module.scss';
@import '~@appclose/ui/src/animations/fadeOutUp.module.scss';

@import '~@appclose/ui/src/scss/mixins';

:root {
  --loader-src: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiAgdmlld0JveD0iMCAwIDEwMCAxMDAiIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIj4KPGNpcmNsZSBjeD0iMzAiIGN5PSI1MCIgZmlsbD0iI2ZkZGMwMyIgcj0iMjAiPgogIDxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9ImN4IiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIgZHVyPSIxcyIga2V5VGltZXM9IjA7MC41OzEiIHZhbHVlcz0iMzA7NzA7MzAiIGJlZ2luPSItMC41cyI+PC9hbmltYXRlPgo8L2NpcmNsZT4KPGNpcmNsZSBjeD0iNzAiIGN5PSI1MCIgZmlsbD0iIzIwYzJkMiIgcj0iMjAiPgogIDxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9ImN4IiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIgZHVyPSIxcyIga2V5VGltZXM9IjA7MC41OzEiIHZhbHVlcz0iMzA7NzA7MzAiIGJlZ2luPSIwcyI+PC9hbmltYXRlPgo8L2NpcmNsZT4KPGNpcmNsZSBjeD0iMzAiIGN5PSI1MCIgZmlsbD0iI2ZkZGMwMyIgcj0iMjAiPgogIDxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9ImN4IiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIgZHVyPSIxcyIga2V5VGltZXM9IjA7MC41OzEiIHZhbHVlcz0iMzA7NzA7MzAiIGJlZ2luPSItMC41cyI+PC9hbmltYXRlPgogIDxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9ImZpbGwtb3BhY2l0eSIgdmFsdWVzPSIwOzA7MTsxIiBjYWxjTW9kZT0iZGlzY3JldGUiIGtleVRpbWVzPSIwOzAuNDk5OzAuNTsxIiBkdXI9IjFzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSI+PC9hbmltYXRlPgo8L2NpcmNsZT4KPCEtLSBbbGRpb10gZ2VuZXJhdGVkIGJ5IGh0dHBzOi8vbG9hZGluZy5pby8gLS0+PC9zdmc+');
}

:global(.notification-item) {
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
}

:global(#root) {
  display: flex;
  flex-direction: column;
}

body {
  background-color: #f8f9fa;
}

@include mobile {
  body {
    background-color: #fff;
  }
}
