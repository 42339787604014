@import '~@appclose/ui/src/scss/mixins';

.preview {
  padding: 60px 50px;
}

.text {
  margin: 0;
  overflow-x: auto;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  white-space: pre-wrap;
}

.file {
  margin-bottom: 16px;
}

.footer {
  margin-top: 70px;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.3px;
}

.footerLinks a:first-child {
  margin-right: 15px;
}

.noteText {
  opacity: 0.5;
}

.notClickable {
  pointer-events: none;
}

.accountStatementBlock {
  padding: 40px;
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.05);
}

.accountStatementText {
  margin: 0 0 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

@include mobile {
  .preview {
    padding: 0;
    border-radius: none;
    box-shadow: none;
  }

  .footer {
    margin-top: 40px;
  }
}
