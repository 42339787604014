.title {
  margin: 0 0 5px;
  font-size: 15px;
  line-height: 22px;
}

.name {
  margin: 0;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.44px;
}

.description {
  composes: title;
  margin: 25px 0 0;
}
