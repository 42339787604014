@import '~@appclose/ui/src/scss/mixins';

.associatedPayments {
  margin-top: 60px;
}

.block {
  margin-bottom: 15px;
  padding: 30px;
}

.block span {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
}

.paymentDate {
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.3px;
  opacity: 0.5;
}

.block strong {
  font-size: 15px;
  line-height: 18px;
}

@include mobile {
  .block {
    padding: 15px 30px;
  }

  .block p {
    margin: 0 0 6px 0;
  }
}
