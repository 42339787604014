.container {
  max-height: 30vh;
  overflow-y: auto;
}

.contactsList {
  padding-left: 0;
  font-size: 15px;
  line-height: 18px;
  list-style: none;
}

.mattersList {
  color: var(--color-info);
  list-style: none;
}
