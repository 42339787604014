@import '~@appclose/ui/src/scss/mixins';
@value color from '../../InvoiceHistoryItem.module.scss';

.subDescription {
  margin-top: 5px;
  color: color;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.3px;
}

@include mobile {
  .subDescription {
    margin-top: 0;
  }
}

.subDescription b {
  color: var(--color-default);
}

.subDescription span + span {
  margin-left: 15px;
}
