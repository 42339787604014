@import '~@appclose/ui/src/scss/mixins';

.actionsPanel {
  margin: 0 0 30px;
  padding: 45px;
}

.header {
  margin: 0 0 24px;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.72px;
  text-align: center;
}

@include mobile {
  .actionsPanel {
    padding: 24px;
  }

  .header {
    margin: 0 0 13px;
    font-size: 16px;
    line-height: 22px;
  }
}
