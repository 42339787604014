@import '~@appclose/ui/src/scss/mixins';

.modal {
  max-width: 1200px !important;
}

.content {
  display: grid;
  grid-column-gap: 60px;
  grid-template-columns: minmax(auto, 500px) minmax(auto, 640px);
}

@include mobile {
  .content {
    grid-template-columns: 100%;
  }

  .fieldset {
    padding-bottom: 60px;
  }

  .fieldset > div {
    padding: 20px 0 0;
  }

  .fieldset > legend > svg {
    margin-bottom: 2px;
  }
}
