.title {
  margin: 0;
}

.field {
  .fieldContent {
    padding-top: 16px;
  }

  > div {
    height: 100%;

    > div {
      height: 100%;
    }
  }
}

.hint {
  margin-left: 8px;
}

.tooltip {
  max-width: 300px;
}
