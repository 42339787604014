.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 172px;
  height: 74px;
  border-radius: 6px;
}

.img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.name {
  color: var(--color-info);
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.3px;
}

.huge {
  max-width: 310px;
  height: 300px;
  max-height: 300px;
}

.huge .name {
  color: #1b1d1e;
  font-weight: bold;
  font-size: 60px;
  line-height: 70px;
  letter-spacing: 0.72px;
  opacity: 0.1;
}

.empty {
  background-color: rgba(235, 238, 238, 0.3);
}
