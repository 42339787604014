.title {
  margin: 0;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.3px;
  opacity: 0.5;
}

.remindersSchedule {
  margin-bottom: 40px;
}

.remindersSchedule tr:last-child {
  border-bottom: 1px solid #ebeeee;
}

.remindersSchedule td {
  height: 50px;
  font-size: 16px;
}

.remindersSchedule td:last-child,
.remindersSchedule td:first-child {
  padding: 0;
}

.scheduled {
  opacity: 0.5;
}

.sent {
  color: var(--color-primary);
}
