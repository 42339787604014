.note {
  margin-top: 30px;
}

.note p {
  margin: 10px 0;
}

.info {
  color: var(--color-info);
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
}
