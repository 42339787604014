.reprintSelectField {
  width: 100px;
  margin-left: 10px;
}

.reprintSelectField label {
  padding-top: 0;
}

.reprintSelectField label div {
  opacity: 1;
}
