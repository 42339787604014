@import '~@appclose/ui/src/scss/mixins';

.timer {
  padding: 6px 6px 6px 19px;
  background-color: #fff;
  border-radius: 22px;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.05);
}

.timers {
  position: fixed;
  top: 0;
  right: 0;
  display: none;
  overflow-y: auto;
  visibility: hidden;
}

.showTimers {
  display: block;
  visibility: visible;
}

@include mobile {
  .timer {
    padding: 3px 6px 3px 15px;
  }
}
