.button {
  min-height: auto;
  margin: 0 12px;
  padding: 5px 16px;
  color: var(--color-primary);
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  white-space: nowrap;
  vertical-align: middle;
  border-bottom-left-radius: 0;
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease-in, background-color 0.5s ease-in;
}

.button:hover {
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.03);
}

.button:active {
  background-color: #f8f9fa;
  box-shadow: none;
}
