@import '~@appclose/ui/src/scss/functions';

.attachedFile {
  display: inline-flex;
  align-items: center;
  width: fit-content;
  padding: 12px 16px;
  text-decoration: none;
  background-color: var(--color-utility);
  border-radius: 8px;

  &.processing {
    .icon {
      flex-basis: 34px;
      height: 24px;
    }
  }
}

.failed {
  background-color: rgba(get-color('system', 'warning'), 0.1);
}

.attachedFile:hover {
  color: var(--color-default);
}

.icon {
  margin-right: 4px;
}

.fileName {
  word-break: break-all;
}

.fileSize {
  white-space: nowrap;
}

.clickable {
  cursor: pointer;
}
