.field {
  margin-top: 60px;
}

.content {
  max-height: calc(5 * 60px);
  overflow-y: auto;
}

.table tbody tr:last-child {
  border-bottom: 1px solid #ebeeee;
}

.activity {
  margin: 0 0 5px;
  color: var(--color-default);
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
}

.emptyResult {
  margin: 0 0 0 20px;
  font-size: 13px;
}

.total {
  min-height: 60px;
}

.totalTitle {
  margin: 0;
  padding-left: 0;
  font-weight: 600;
  font-size: 13px;
}

.totalAmount {
  margin-right: 20px;
  font-weight: bold;
}
