@import '~@appclose/ui/src/scss/mixins';

.fees {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 15px 10px;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
}

.text {
  white-space: nowrap;
}

.amounts {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

@include mobile {
  .fees {
    grid-template-areas:
      'text image'
      'amounts amounts';
    grid-template-columns: repeat(2, auto);
    justify-content: center;
  }

  .text {
    grid-area: text;
    text-align: center;
  }

  .amounts {
    grid-area: amounts;
  }

  .image {
    grid-area: image;
    margin: 0 auto;
  }
}

@media (max-width: 440px) {
  .fees {
    grid-template-areas:
      'text text'
      'image image'
      'amounts amounts';
  }

  .amounts {
    justify-content: space-around;
  }
}

@media (max-width: 335px) {
  .amounts {
    flex-wrap: wrap;
  }
}
