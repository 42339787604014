@import '~@appclose/ui/src/scss/mixins';

$iconSize: 50px;

.financialBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.05);
}

.icon {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: $iconSize;
  height: $iconSize;
  color: #fff;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 1px;
  background-color: #aeb7bb;
  border-radius: 10px;
}

.content {
  margin-left: 24px;
}

.titleContainer {
  margin: 0 0 4px;
}

.hint {
  max-width: 240px;
}

.hintWithTitle {
  margin-left: 10px;
}

.statistics {
  margin-left: 10px;
}

.header:not(:only-child) {
  margin-bottom: 25px;
}

.minimize {
  min-height: auto;
  padding: 24px;
}

.arrowIcon {
  margin-left: auto;
}

@include mobile {
  .financialBlock:not(.minimize) {
    padding: 32px;
  }

  .header:not(:only-child) {
    margin-bottom: 16px;
  }
}
