.paid {
  color: var(--color-success);
}

.scheduled {
  opacity: 0.5;
}

.failed {
  color: var(--color-warning);
}
