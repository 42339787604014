@font-face {
  font-weight: normal;
  font-family: 'MICREncoding';
  font-style: normal;
  src: url('./assets/fonts/MICR Encoding/MICR Encoding.ttf');
  font-display: swap;
}

.check {
  margin: 60px 0 0;
  padding: 20px 30px;
  border: 4px solid var(--color-brand);
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.05);
}

.firmAddress > p {
  margin: 0;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.3px;
}

.text {
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
}

.label {
  flex-shrink: 0;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
}

.underlined {
  border-bottom: 2px solid var(--color-default);
}

.date {
  composes: text;
  composes: underlined;
  width: 150px;
  margin-left: 10px;
  padding: 5px 0;
}

.dollar {
  margin-top: -4px;
  margin-left: -8px;
}

.borderLeft {
  height: 24px;
  padding: 0 5px;
  border-left: 2px solid var(--color-default);
}

.amount {
  composes: text;
  width: 100%;
  margin: 0;
  padding: 5px;
  font-weight: 900;
  border: 2px solid var(--color-default);
}

.amountText {
  margin-top: 10px;
}

.dollarText {
  composes: text;
  margin: 0;
  line-height: 40px;
}

.memo {
  composes: underlined;
  composes: text;
  width: 100%;
  margin: 0 0 0 10px;
  padding: 0 5px;
}

.mirc {
  margin: 20px 0 0;
  font-size: 28px;
  font-family: 'MICREncoding', sans-serif;
  user-select: none;
}
