.tooltipIcon {
  margin-left: 10px;
}

.file {
  margin: 8px 20px 0 0;
}

.remove {
  margin-left: 8px;
  cursor: pointer;
}
