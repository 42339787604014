@import '~@appclose/ui/src/components/Typography/scss/mixins';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex-grow: 1;
  margin: 22px 0 44px;
  text-align: center;
}

.buttons {
  justify-content: center;
}
