.successIcon {
  margin-top: 60px;
}

.congratulations {
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.44px;
}

.congratulationsDescription {
  margin: 0 0 50px;
  font-size: 15px;
  line-height: 22px;
}
