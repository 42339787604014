.overlay {
  max-height: calc(6 * 64px + 30px);
  overflow-y: auto;
}

.total {
  min-height: 60px;
}

.totalTitle {
  margin: 0;
  padding-left: 0;
  font-weight: 600;
  font-size: 13px;
}

.totalAmount {
  margin-right: 20px;
  font-weight: bold;
}
