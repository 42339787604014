.table tbody:only-child tr {
  border-top: 1px solid #ebeeee;
}

.table tbody:only-child tr:last-child {
  border-bottom: 1px solid #ebeeee;
}

.table td:first-child,
.table th:first-child {
  padding-left: 0;
}

.table td:last-child,
.table th:last-child {
  padding-right: 0;
  text-align: right;
}

.table tbody td p:not(:only-child):not(:last-child),
.table th p:not(:only-child):not(:last-child) {
  margin: 0 0 12px;
}
