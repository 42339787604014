.remaindersNote {
  margin: 0 0 30px;
  font-size: 15px;
  line-height: 22px;
}

.emailField {
  font-size: 18px;
}

.group {
  margin-top: 40px;
}

.field {
  margin-top: 20px;
}

.repeat {
  composes: field;
}

.repeat > div:first-child {
  width: 110px;
}

.repeat > div:nth-child(2) {
  margin-left: 20px;
}

.limits {
  margin: 10px 0 0;
}

.limitsLabel {
  margin: 0;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.3px;
  opacity: 0.5;
}

.limitInput {
  width: 65px;
  padding: 0 12px 0 0;
}
.limitInput:not(:first-child) {
  padding: 0 12px;
}

.limitInput > label {
  padding: 0;
}

.limitInputText {
  text-align: center;
}

.limitDescription {
  margin: 0 12px 0 0;
  padding-top: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0;
}

.disabled {
  opacity: 0.5;
}

.buttons {
  margin-top: 40px;
}
