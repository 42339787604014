@import '~@appclose/ui/src/scss/mixins';
@import '~@appclose/ui/src/scss/functions';

.entryRow {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}

.entryDetails {
  width: 100%;
  height: 60px;
  padding: 20px 16px;
  border-bottom: 1px solid get-color('background', 'tertiary');
}

.entryDetails > div:first-child {
  display: inline-flex;
}

.amount {
  display: inline-block;
  width: 160px;
  margin-left: 48px;
}

@include mobile {
  .entryDetails {
    padding: 0;
  }

  .entryRow {
    flex-wrap: wrap;
    justify-content: flex-end;
  }
}
