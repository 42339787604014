@import '~@appclose/ui/src/scss/mixins';

.header {
  margin-bottom: 20px;
}

.invoiceInfo {
  margin-bottom: 50px;
}

.creditMemo {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.5px;
}

.creditMemoIcon {
  margin-left: 16px;
}

@include mobile {
  .tabList {
    padding: 20px 0;
    overflow-x: auto;
  }

  .tabList > li:not(:first-child) {
    margin-left: 24px;
  }
}
