.modes {
  position: sticky;
  top: 0;
  z-index: 1;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 7px;
  width: 190px;
  margin: 0 0 -55px auto;
  padding: 15px 20px;
  text-align: center;
  background: #fff;
  border-radius: 26px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);
}

.button {
  min-height: auto;
  padding: 5px 15px;
  font-size: 13px;
}
