.fieldset {
  margin-bottom: 40px;
}

.body > tr {
  border-bottom: 1px solid #ebeeee;
}

.head > tr > th,
.head > tr:hover {
  z-index: 1;
  background-color: #fff !important;
  border-bottom: 1px solid #ebeeee;
}

.uncleared {
  opacity: 0.5;
}

.empty {
  margin: 0;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
}
